.tooltip-gdpr {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  padding-bottom: 5px;
  a {
    text-decoration: none;
  }
  .tooltiptext-over-gdpr {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: $color-cl-davy-grey;
    border: 1px solid $color-dusty-gray;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
    line-height: 14px;
    overflow-y: scroll;
    height: 75px;
    bottom: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0%;
      border-width: 5px;
      border-color: transparent transparent $color-cl-davy-grey transparent;
    }
  }
  &:hover .tooltiptext-over-gdpr {
    visibility: visible;
    opacity: 1;
  }
}
