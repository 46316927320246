@if $password_strength {
  .profile-password-update {
    &__fieldset {
      label {
        pointer-events: none;
      }
    }
  }
  #password_reset {
    .password_container2 {
      margin-bottom: 20px;
    }
  }
  #confirm {
    .guest_checkout_wrapper {
      @media #{$medium-up} {
        .form-item {
          &.password {
            overflow: visible;
          }
        }
      }
    }
  }
  #registration {
    .profile-form-container {
      .profile-info__change-pwd-link {
        @media #{$medium-up} {
          padding: 30px 0 10px;
        }
      }
    }
  }
  .password-field {
    position: relative;
    #password_reset & {
      margin-bottom: 20px;
    }
    &__info {
      display: flex;
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      #password_reset & {
        margin-bottom: 10px;
      }
      &::before {
        @media #{$medium-up} {
          content: '';
          position: absolute;
          top: 37%;
          #{$rdirection}: 100%;
          border-width: 5px;
          border-style: solid;
          border-color: transparent $color-btn-grey transparent transparent;
        }
        #signin &,
        #gnav_signin & #confirm &,
        .loyalty_popover & {
          #{$ldirection}: 50%;
          border-color: $color-btn-grey transparent transparent transparent;
          top: 100%;
        }
        #registration & {
          top: 57px;
          #{$rdirection}: 49%;
          border-color: $color-btn-grey transparent transparent transparent;
        }
      }
      @media #{$medium-up} {
        #confirmation-page &,
        #password_reset &,
        .signin-overlay-loyalty &,
        .registration-page &,
        #signin &,
        #billing &,
        #gnav_signin &,
        #registration &,
        #confirm &,
        .loyalty_popover & {
          position: absolute;
          border: 1px solid $color-black;
          width: 21%;
        }
        #confirmation-page & {
          #{$ldirection}: 35%;
          top: -20px;
          width: auto;
        }
        #password_reset & {
          top: -10%;
          width: auto;
          #{$ldirection}: 40%;
        }
        #confirm & {
          bottom: -10px;
          line-height: 1;
          width: auto;
          #{$ldirection}: 210px;
          &:before {
            #{$ldirection}: 50%;
            border-color: $color-btn-grey transparent transparent transparent;
            top: 100%;
          }
        }
        #signin &,
        #gnav_signin,
        #billing & {
          width: 33%;
          #{$ldirection}: 0%;
          bottom: 40px;
          &:before {
            #{$ldirection}: 50%;
            border-color: $color-btn-grey transparent transparent transparent;
            top: 100%;
          }
        }
        #billing & {
          width: inherit;
        }
        #gnav_signin &,
        .loyalty_popover & {
          &.hidden {
            display: none;
          }
        }
        .loyalty_popover & {
          width: 80%;
          #{$ldirection}: 5%;
          top: -68px;
        }
        #registration & {
          width: auto;
          bottom: 5px;
          #{$ldirection}: 16%;
        }
        .registration-page & {
          width: 50%;
          #{$ldirection}: 105%;
          top: -17px;
          @media screen and (min-width: $medium-m) {
            width: 100%;
          }
        }
      }
    }
    #registration & {
      clear: both;
    }
    &__rules {
      column-count: 2;
      .registration-page &,
      #confirmation-page &,
      #password_reset &,
      #gnav_signin &,
      #signin & {
        @media #{$medium-up} {
          column-count: 1;
        }
        @media screen and (max-width: $medium-mid) {
          padding: 0 10px;
        }
      }
      padding: 10px;
      line-height: 0.8;
      font-size: 0.8em;
      list-style: none;
      > li {
        display: flex;
        align-items: center;
        padding: 3px 0;
        color: $color-btn-grey;
        transition: 0.2s;
        white-space: nowrap;
        &::before {
          content: $cross-mark;
          display: inline-block;
          color: $color-red;
          font-size: 1em;
          line-height: 0;
          margin: 0 6px 0 0;
          transition: 0.2s;
        }
        &.pass {
          color: $color-green;
          &::before {
            color: $color-green;
            content: $check-mark;
          }
        }
      }
    }
  }
  // Password reset overlay in profile preference page
  .profile-password-update {
    &__body {
      position: relative;
    }
    &__header {
      font-size: 22px;
    }
    &__fieldset {
      margin-top: 12px;
      padding-top: 20px;
      .form-item {
        margin-bottom: 20px;
        width: 100%;
        input[type='password'] {
          width: 100%;
        }
      }
    }
    &__meter-status {
      font-weight: 700;
    }
    &__meter {
      height: 8px;
      border-radius: 4px;
      &--container {
        margin-top: 6px;
        background-color: $color-light-gray;
      }
      &--progress {
        background-color: $color-light-gray;
        width: 0;
        transition: all 0.3s linear;
      }
    }
    &__meter-wrapper {
      .profile-password-update {
        &__strength-list {
          display: inline-block;
          li {
            display: none;
            font-style: italic;
          }
        }
      }
      &.weak,
      &.no-score {
        .profile-password-update {
          &__meter {
            &--progress {
              width: 0;
            }
          }
          &__strength-list {
            .no-score {
              display: inline-block;
            }
          }
        }
      }
      &.bad {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-red;
              width: 25%;
            }
          }
          &__strength-list {
            .bad {
              display: inline-block;
            }
          }
        }
      }
      &.average {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-average;
              width: 50%;
            }
          }
          &__strength-list {
            .average {
              display: inline-block;
            }
          }
        }
      }
      &.good {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-good;
              width: 75%;
            }
          }
          &__strength-list {
            .good {
              display: inline-block;
            }
          }
        }
      }
      &.strong {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-green;
              width: 100%;
            }
          }
          &__strength-list {
            .strong {
              display: inline-block;
            }
          }
        }
      }
    }
    &__action {
      display: flex;
      width: 100%;
      margin: 20px 0;
      input[type='submit'] {
        &::disabled,
        &.disabled {
          color: $color-light-gray;
          background-color: $color-white;
          cursor: not-allowed;
        }
      }
    }
    &__button {
      #foreground-node & {
        flex-grow: 1;
        height: 40px;
        &:first-child {
          margin-#{$rdirection}: 10px;
        }
      }
    }
    &__loader {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      display: none;
      background: $color-white;
      opacity: 0.5;
      &.show {
        display: block;
      }
    }
    &__loading {
      top: 20%;
      #{$ldirection}: 45%;
      position: absolute;
    }
    .error_messages {
      width: auto;
      word-wrap: break-word;
      color: $color-red;
    }
    &.registration-page__content {
      #foreground-node & {
        .profile-password-update__button {
          margin-top: 0;
          width: inherit;
          height: 40px;
          flex-grow: 1;
          font-size: 16px;
        }
      }
    }
  }
}

#register-form {
  @media #{$medium-up} {
    .password-field__info {
      width: 21%;
    }
  }
}
