/* Checkout Signin container section */
.wrapper {
  .social-login__container {
    width: 60%;
    height: 400px;
  }
}

#signin {
  #wrapper {
    #signin-block {
      .social-login {
        width: 100%;
        margin: 0;
        .disclaimer,
        .social-login__email-opt-in {
          input[type='checkbox'] {
            padding: 7px;
            @media #{$medium-only} {
              width: 18px;
              height: 0;
            }
          }
          label {
            margin-#{$ldirection}: 0;
            color: $color-black;
            @media #{$small-only} {
              padding: 7px;
            }
          }
          padding: 0;
          @media #{$medium-only} {
            display: flex;
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
    .checkout_signin {
      .social-login {
        width: 100% !important;
        margin: 0;
      }
    }
  }
}

#main {
  padding-bottom: 150px;
}
/* Account&Checkout signin and order  page */
.wrapper,
.main {
  .social-login {
    .disclaimer,
    .fb-disclaimer-container {
      text-align: #{$ldirection};
      margin-top: 20px;
      display: flex;
    }
    .fb-disclaimer {
      padding-#{$ldirection}: 10px;
    }
  }
}

.locale_en_se {
  .signin_container {
    .social-login {
      .social-login__opt-in-label {
        margin-top: 7px;
      }
    }
  }
  #signin-block {
    .social-login__opt-in-label {
      margin-top: 7px;
      padding-#{$ldirection}: 24px;
    }
    .social-login__email-opt-in input[type='checkbox'] {
      position: absolute;
    }
  }
}
/* Gnav */
.gnav-my-account-v1 {
  @media #{$xlarge-only} {
    height: 500px;
    overflow-y: scroll;
  }
  .social-login {
    .social-login__email-opt-in {
      input[type='checkbox'] {
        margin-top: 16px;
      }
    }
  }
}
/* profile preference page */
.social-info {
  &__connect {
    display: block;
  }
  .fb-overlay-social-info {
    display: inline-block;
    top: 2px;
  }
  .disclaimer {
    padding-top: 15px;
  }
}

#registration.device-mobile {
  .registration_content {
    .fb-disclaimer {
      font-weight: 100;
    }
  }
}
/* Order confirm page */
#confirm {
  .confirmation-content {
    .social-login__info {
      text-align: #{$ldirection};
    }
    .social-login {
      h2 {
        padding: 0;
      }
      .disclaimer {
        input[type='checkbox'] {
          padding: 7px;
        }
      }
      .social-login__email-opt-in {
        input[type='checkbox'] {
          padding: 7px;
          width: auto;
          height: auto;
        }
      }
    }
  }
  .fb-overlay-container {
    margin-#{$ldirection}: 0;
  }
  .social-login__opt-in-label {
    color: $color-black;
  }
  .pg_wrapper {
    #main.multi {
      #sidebar {
        margin-top: 0;
      }
      .section-box {
        margin: 0;
      }
      .social-login {
        margin-bottom: 20px;
        .social-login__container {
          @media #{$medium-up} {
            padding-top: 0;
          }
        }
        .social-login__email-opt-in {
          label {
            color: $color-black;
            @media #{$medium-up} {
              line-height: 19px;
            }
          }
          .fb-disclaimer {
            @media #{$medium-up} {
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}
/* Newletter optin label */
.social-login {
  .social-login__email-opt-in {
    .social-login__opt-in-label {
      font-family: $base-font-family !important;
    }
  }
}
/* Tooltip */
.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
}

.fb-overlay-social-info,
.fb-overlay-container {
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.social-login,
.social-info {
  .tooltip {
    text-align: left;
    margin-top: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    font-weight: bold;
    color: $color-cl-soft-black;
    font-family: $base-bold-font-family;
    a {
      color: $color-white;
      text-decoration: underline;
    }
    .tooltiptext-over {
      visibility: hidden;
      width: 100%;
      background-color: $color-cl-davy-grey;
      color: $color-white !important;
      text-align: justify;
      border-radius: 6px;
      padding: 10px 5px;
      position: absolute;
      z-index: 1;
      left: 0%;
      opacity: 0;
      transition: opacity 0.3s;
      font-weight: normal;
      bottom: 125%;
      font-family: $base-font-family;
    }
    .tooltiptext-over::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-cl-davy-grey transparent transparent transparent;
    }
    &:hover .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
}
/* Account Profile Page */
.signin_container {
  .social-login {
    .social-login__email-opt-in {
      display: flex;
      .social-login__opt-in-label {
        padding-#{$ldirection}: 0;
        margin-#{$ldirection}: 10px;
      }
    }
  }
}
