/* Info popup styles for privacy policy */
.info-link {
  cursor: pointer;
  margin-bottom: 15px;
  border: 0px;
  display: inline-block;
  .tools-pages {
    width: 100px;
    margin: 0 auto;
  }
}

.info-icon {
  cursor: pointer;
  @if $cr19 == true {
    background: $cr19-bg-black;
  } @else {
    background: #5dba98;
  }
  color: $color-white;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
  text-transform: lowercase;
  padding: 1px 0px 0px 9px;
  &:hover {
    @if $cr19 == true {
      background: $cr19-bg-med;
      color: $cr19-border-light;
    }
  }
}

.info-message-txt {
  display: none;
}

.info-msg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000000;
  background-color: $color-white;
  padding: 20px;
  width: 90%;
  border: 1px solid #000;
  max-width: 500px;
  &__arrow {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0px;
    border: 10px solid transparent;
    border-bottom-color: $color-black;
    top: -10px;
    margin-top: -10px;
    &.top-right-arrow {
      left: 478px; /* 500px minus Border 2px and Triangle 20px */
    }
  }
  &__txt {
    position: relative;
  }
  &__close {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

#bt_notification {
  div.bt-content {
    .bt-pp-section,
    .bt-cookie-section {
      padding: 15px;
    }
    .bt-pp-section {
      background-color: #930808;
      color: $color-white;
      .bt-content__sub-hdr {
        color: $color-white;
      }
    }
    .bt-cookie-section {
      .bt-content__sub-hdr {
        color: #222;
      }
    }
    .bt-content__link {
      border: 0;
    }
    .btn-wrap {
      margin-top: 10px;
      border: 1px solid $color-white;
      .button {
        width: 100%;
        font-weight: normal;
        padding: 0 3px;
        line-height: 25px;
        font-size: 11px;
        height: auto;
        color: $color-white;
      }
    }
    &.bt-bottom-right {
      padding: 0px;
    }
    z-index: 1000000;
  }
}

.colorbox_scroll {
  overflow-y: hidden;
}

.info-gdpr-for-left,
.info-gdpr-right-icon {
  float: #{$ldirection};
}

.info-gdpr-right-icon {
  margin-#{$ldirection}: 10px;
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .info-icon {
          color: $color-white;
        }
      }
      .info-message-txt-gdpr {
        display: none;
      }
    }
  }
}

.footer-top-v1 {
  .footer-gdpr {
    padding-left: 48%;
  }
}
/* GDPR Live Chat Style */
.section-chatprivacypolicy {
  #page-header-wrapper,
  #top_wrapper,
  #footer,
  #top,
  .page-footer-region,
  .sticky-footer-live-chat,
  .nav-promo,
  .header-gnav-navigation {
    display: none;
  }
  .LPMcontainer,
  .optanon-alert-box-wrapper,
  .ins-preview-wrapper {
    display: none !important;
  }
}
/* Waitlist GDPR */
@import 'components/_waitlist';

.info-link-message {
  background: url('/media/images/checkout/privacy-policy-info-icon.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  display: block;
  float: $ldirection;
  height: 20px;
  opacity: 0.9;
  position: relative;
  text-align: $ldirection;
  text-indent: -9999px;
  width: 20px;
}

.info-message-gdpr {
  margin-top: 10px;
  .info-link {
    text-decoration: underline;
    padding: 2px;
  }
}
