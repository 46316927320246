.info-msg {
  width: 80%;
}

.device-mobile {
  .popup-offer-v1 {
    .outer-wrap {
      height: 290px;
    }
  }
}

.email_waitlist_popup {
  height: 300px;
  width: 100%;
}
